import { Box, Flex } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { isAdminRoute, isHomeRoute } from '@/helpers/routeHelpers';

import GlobalNavBar from './GlobalNavBar';
import Header from './Header';
import { ModalsContext } from '@/contexts/ModalsContext';
import dynamic from 'next/dynamic';
import { poppins } from '@/utils/fonts';
import useIsMobileDisplay from '@/hooks/useIsMobileDisplay';
import { useRouter } from 'next/router';

const AdminHeader = dynamic(() => import('@/components/layout/admin/AdminHeader'), { ssr: false });
const Footer = dynamic(() => import('@/components/layout/footer/Footer'), { ssr: false });
const GeoblockContent = dynamic(() => import('./GeoblockedContent'), { ssr: false });
const GasEstimateErrorModal = dynamic(() => import('@/components/modals/GasEstimateErrorModal'), { ssr: false });
const AnnouncementBanner = dynamic(() => import('@/components/layout/banners/AnnouncementBanner'), { ssr: false });
const AcceptTosModal = dynamic(() => import('@/components/modals/AcceptTosModal'), { ssr: false });

interface Props {
  children: JSX.Element;
}

const Layout: React.FC<Props> = ({ children }: Props) => {
  const router = useRouter();
  const { acceptTosIsOpen, onCloseAcceptTos } = useContext(ModalsContext);

  const isHome = isHomeRoute(router.pathname);
  const isAdmin = isAdminRoute(router.pathname);
  const isMobile = useIsMobileDisplay();

  return (
    <Flex className={poppins.className} minH="100vh" flexDirection="column" align="center" flexShrink={0}>
      <GlobalNavBar backgroundColor={isHome || isAdmin || isMobile ? 'white' : 'transparent'} />

      <GeoblockContent />

      <GasEstimateErrorModal />

      <AnnouncementBanner />

      <AcceptTosModal isOpen={acceptTosIsOpen} onClose={onCloseAcceptTos} />

      {isAdmin ? <AdminHeader pathname={router.pathname} /> : <Header />}

      <Box flex={1} width="100%">
        <main style={{ height: '100%', width: '100%' }}>{children}</main>
      </Box>

      {!isAdmin && <Footer />}
    </Flex>
  );
};

export default Layout;
